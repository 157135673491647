<template>
  <el-container>
    <el-header class="header" height="2.70833vw">
      <!-- 左侧 -->
      <div class="header-left" v-show="checkedFileId.length < 1">
        <div class="header-btn">
          <label class="fileInput"
            >点击上传文件<input
              type="file"
              @change="handleFileChange"
              name="fileUploadSingleFile"
              id="fileUploadSingleFile"
          /></label>
        </div>
        <!--
        <div class="dropdown">
          <div class="dropdown-toggle header-btn" @click="handleUploadBtnClick">
            上传文件
            <i
              class="iconfont icon-arrow-down-filling"
              :class="dropdownState ? 'up' : ''"
            ></i>
          </div>
          <ul class="dropdown-list" v-show="dropdownState">
            <li class="dropdown-list-item">
              <label class="fileInput"
                >上传单个文件<input
                  type="file"
                  @change="handleFileChange"
                  name="fileUploadSingleFile"
                  id="fileUploadSingleFile"
              /></label>
            </li>
            <li
              class="dropdown-list-item"
              @click="uploadRARDialogVisible = true"
            >
              上传压缩包
            </li>
          </ul>
             
        </div> -->
        <div
          class="header-btn"
          @click="createFolder(editTextId)"
          v-show="!showTableCustomColumn || filePath.length > 1"
        >
          <img src="~a/workbench/add.png" alt="" /> 新建文件夹
        </div>
      </div>
      <div class="header-left fileBtn-group" v-show="checkedFileId.length > 0">
        <!-- <button class="header-btn" @click="handleFile('copy')">
          <img src="~a/workbench/fileCopy.png" alt="" /> 复制
        </button> -->
        <button class="header-btn" @click="handleFile('del')">
          <img src="~a/workbench/fileDel.png" alt="" /> 删除
        </button>
        <button class="header-btn" @click="handleFile('move')">
          <img src="~a/workbench/fileMove.png" alt="" /> 移动
        </button>
        <button class="header-btn" @click="handleFile('cancel')">
          取消选择
        </button>
      </div>
      <!-- 右侧输入框 -->
      <div class="header-right">
        <Search
          v-model="keyword"
          @search="handleSearch"
          @close="handleSearchClose"
        />
      </div>
    </el-header>
    <el-container class="main-container">
      <el-header class="main-header" height="2.70833vw">
        <!-- 文件选择路径 -->
        <div class="filePath">
          <!-- 返回上一级 -->
          <div class="filePath-back" v-if="filePath.length > 0">
            <el-button type="text" class="filePath-back-btn" @click="checkOut"
              >返回上一级</el-button
            >
            <span class="splitLine"></span>
          </div>
          <!-- 查看全部 -->
          <el-button
            type="text"
            class="filePath-checkAll"
            :class="filePath.length ? 'active' : ''"
            @click="checkRoot"
            >全部文件</el-button
          >
          <!-- 当前路径 -->
          <div class="filePath-list">
            <div
              v-for="(item, index) in filePath"
              :key="index"
              class="filePath-list-item"
            >
              <i class="iconfont icon-arrow-right"></i>
              <span class="filePath-list-item-name">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <!-- 已加载数量 -->
        <span class="loading-num">已加载{{ tableData.length }}个</span>
      </el-header>
      <el-main class="main">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :max-height="tableMaxHeight"
          :default-sort="{ prop: 'updateDateText', order: 'descending' }"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
          @row-contextmenu="handleRowContextmenu"
          @sort-change="handleSortChange"
          :row-class-name="tableRowClassName"
          v-loading="loading"
          :element-loading-text="loadingText"
        >
          <el-table-column
            type="selection"
            :selectable="checkboxSelect"
            width="57"
          >
          </el-table-column>
          <el-table-column
            :label="
              checkedFileId.length >= 1
                ? '已选中' + checkedFileId.length + '个文件/文件夹'
                : '文件名'
            "
            prop="name"
            sortable="custom"
            class="tableColumn"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- <list-tooltip content="当前文件夹正在上传文件,禁止编辑">
                <img
                  class="folderImg1"
                  src="~a/workbench/fileTip.png"
                  alt=""
                  v-if="scope.row.folder == 1 && uploadStatus"
                />
              </list-tooltip> -->
              <img
                class="folderImg"
                src="~a/workbench/folder.png"
                alt=""
                v-if="scope.row.folder == 1"
              />
              <img
                class="folderImg"
                src="~a/workbench/file.png"
                alt=""
                v-else
              />

              <!-- 文件名称 -->
              <EditText
                v-if="scope.row.id == editTextId"
                class="editText"
                :id="scope.row.id"
                :value="scope.row.name"
                :maxlength="35"
                @edit="text => handleNameEdit(text, scope.row)"
                @del="handleRefreshData"
                @keyup.esc.native="handleRefreshData"
              >
              </EditText>
              <template v-else>
                <span
                  v-if="scope.row.folder === 1"
                  class="table-folderName"
                  @click.stop="checkIn(scope.row, '查看下级')"
                >
                  {{ scope.row.name }}
                </span>
                <span v-else class="table-fileName">
                  {{ scope.row.name }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="更新时间"
            prop="updateDateText"
            sortable="custom"
          />
          <el-table-column label="大小" sortable="custom" prop="sizeFormat">
            <template slot-scope="scope">
              <span v-if="scope.row.folder === 1">-</span>
              <span v-else>{{ scope.row.sizeFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="showTableCustomColumn" label="目录">
            <template slot-scope="scope" v-if="showTableCustomColumn">
              <p
                class="showTableCustomColumn"
                @click.stop="checkIn(scope.row, '查看目录')"
              >
                {{ scope.row.parentName ? scope.row.parentName : '全部文件' }}
              </p>
            </template>
          </el-table-column>
          <template #empty>
            <div class="noData" v-show="!loading">
              <img class="noData-img" src="~a/workbench/noFile.png" alt="" />
              <div class="noData-bottom">
                <p class="noData-bottom-text">
                  {{
                    showTableCustomColumn
                      ? '当前结果为空'
                      : '当前结果为空，你可以选择：'
                  }}
                </p>
                <div
                  class="noData-bottom-btn-group"
                  v-show="!showTableCustomColumn"
                >
                  <label class="noData-bottom-btn fileInput">
                    <img src="~a/workbench/uploadFile.png" alt="" />
                    <p>上传文件</p>
                    <input
                      type="file"
                      @change="handleFileChange($event)"
                      name="fileUploadSingleFile"
                      id="fileUploadSingleFile"
                    />
                  </label>
                  <!-- <label class="noData-bottom-btn fileInput">
                    <img src="~a/workbench/uploadFolder.png" alt="" />
                    <p>上传文件夹</p>
                    <input
                      type="file"
                      directory
                      webkitdirectory
                      @change.stop="handleFolderChange($event)"
                    />
                  </label> -->
                </div>
              </div>
            </div>
          </template>
        </el-table>
        <!-- 分页组件 -->
        <Pagination
          @handleCurrentChange="handleCurrentChange"
          :page="page"
          :total="total"
          :pageSize="showTableCustomColumn ? 15 : 20"
        />
        <!-- 右键菜单 -->
        <div
          v-if="contextmuenPosition && checkedFileId.length !== 0"
          class="contextMenu"
          :style="{ ...contextmuenPosition }"
          @click="contextmuenPosition = ''"
        >
          <ul class="contextMenu-list">
            <li
              class="contextMenu-list-item"
              @click="reaname"
              v-show="checkedFileId.length === 1"
            >
              重命名
            </li>
            <li class="contextMenu-list-item" @click="handleFile('del')">
              删除
            </li>
            <!-- <li class="contextMenu-list-item" @click="handleFile('copy')">
              复制
            </li> -->
            <li class="contextMenu-list-item" @click="handleFile('move')">
              移动
            </li>
          </ul>
        </div>
      </el-main>
    </el-container>
    <!-- 上传压缩包窗口 -->
    <el-dialog
      title="上传压缩包"
      :visible.sync="uploadRARDialogVisible"
      width="26.2vw"
      class="uploadRARDialog"
      destroy-on-close
    >
      <el-form class="form" label-position="left" label-width="5.1vw">
        <el-form-item label="选择类型：">
          <el-select v-model="uploadRARFile" placeholder="选择上传类型">
            <el-option
              v-for="item in uploadRARFileOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片：">
          <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
          >
            <img src="~a/workbench/uploadRar.png" class="uploadRar" />
            <div class="el-upload__text">
              <img src="~a/workbench/uploadPuls.png" /><span>添加压缩包</span>
            </div>
            <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </el-upload>
        </el-form-item>
        <el-form-item class="uploadRARDialog-announcements">
          <span slot="label"
            ><img src="~a/workbench/uploadWarning.png" />注意事项：</span
          >
          <p class="uploadRARDialog-announcements-p1">
            1.上传过程中切记不要关闭当前网页
          </p>
          <p>xxxxxxxxxxxxxxxxxxxxxxxx</p>
          <p>xxxxxxxxxxxxxxxxxx</p>
          <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 复制、移动窗口 -->
    <el-dialog
      :title="moveOrCopyTitle"
      :visible.sync="fileMoveDialogVisible"
      width="40%"
      class="fileMoveDialog"
      destroy-on-close
    >
      <el-header class="fileMoveDialog-header">
        <!-- 文件选择路径 -->
        <div class="fileMoveDialog-header-filePath">
          <div
            class="handleTxet"
            v-if="fileMovePath.length > 0"
            @click="checkOut('fileMove')"
          >
            返回上一级 |
          </div>
          <!-- 查看全部 -->
          <div class="handleTxet" @click="checkRoot('fileMove')">
            <img
              v-if="!fileMovePath.length"
              class="folderImgTitle"
              src="~a/workbench/folder.png"
              alt=""
            /><span :class="!fileMovePath.length ? 'qbwj' : ''">全部文件</span>
          </div>
          <!-- 当前路径 -->
          <div v-for="(item, index) in fileMovePath" :key="index" class="list">
            <i class="iconfont icon-arrow-right"></i>
            <span class="list-item">{{ item.name }}</span>
          </div>
        </div>
      </el-header>
      <el-table
        :data="fileList"
        height="47vh"
        :show-header="false"
        class="fileMoveTable"
        v-loading="loading"
        v-el-table-infinite-scroll="loadMoreData"
      >
        <el-table-column
          label="文件名"
          prop="name"
          class="tableColumn"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <img class="folderImg" src="~a/workbench/folder.png" alt="" />
            <div class="folderName" @click="checkMoveIn(scope.row)">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <div class="noData">
            <img src="~a/workbench/upload-2.png" alt="" />
            <div class="noData-bottom">
              {{ moveOrCopyTitle }}
              {{
                fileMovePath.length >= 1
                  ? fileMovePath[fileMovePath.length - 1].name
                  : '全部'
              }}
              文件夹
            </div>
          </div>
        </template>
      </el-table>
      <div class="fileMoveDialog-btn">
        <el-button class="close" @click="fileMoveDialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleMove"
          >{{ moveOrCopyTitle }}此</el-button
        >
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import EditText from 'c/basic/EditText';
import Search from 'c/workbench/Search';
import { time } from 'p/Times.js';
import Pagination from 'c/basic/Pagination.vue';
// import ListTooltip from 'c/basic/ListTooltip.vue';
import ElTableInfiniteScroll from 'el-table-infinite-scroll';
import $bus from 'p/utils';
import axios from 'p/axios';
import BMF from 'browser-md5-file';
export default {
  name: 'FileManager',
  components: {
    EditText,
    Search,
    Pagination
    // ListTooltip
  },
  directives: {
    'el-table-infinite-scroll': ElTableInfiniteScroll
  },
  data() {
    return {
      fileTasks: {}, // 文件上传任务
      dropdownState: false, // 上传文件
      keyword: '', // 关键词查询
      showTableCustomColumn: false, // 表格显示自定义列
      tableData: [], // 表格数据
      fileList: [], // 文件列表
      checkedFile: [], // 已勾选的文件
      checkedFileId: [], // 已勾选的文件ID
      filePath: [], // 记录点击路径
      fileMovePath: [], // 记录移动窗口点击路径
      contextmuenRow: '', // 右键选中行
      contextmuenPosition: '', // 右键菜单位置
      currentTime: '', // 当前时间
      eidtFileNameId: '', // 编辑
      sortChangeNum: 0, // 排序
      fileMoveDialogVisible: false, // 文件夹移动窗口
      uploadRARDialogVisible: false, // 上传压缩包
      moveOrCopyTitle: '', // 移动复制窗口标题
      loading: false, // 加载动效
      loadingText: '拼命加载中', // 加载中文字提示
      fileMoveCopyLoading: false, // 复制移动窗口加载动效
      page: 1, // 页码
      total: 0, // 数据数
      fileMoveOrCopyPage: 0, // 移动复制窗口页码
      fileMoveOrCopyTotalPage: 0, // 移动复制窗口总页码
      tableMaxHeight: 630, // 表格最大高度
      uploadRARFile: '1', // 上传压缩包类型
      uploadRARFileOptions: [
        { value: '1', label: '倾斜摄影模型' },
        { value: '2', label: '倾斜摄影模型1' }
      ]
    };
  },
  computed: {
    editTextId: {
      get() {
        return this.$store.state.common.editTextId;
      }
    },
    fileTransList: {
      get() {
        return this.$store.state.file.fileTransList;
      },
      set(val) {
        this.$store.commit('file/setFileTransList', val);
      }
    },
    transferPanelToggle: {
      get() {
        return this.$store.state.file.transferPanelToggle;
      },
      set(val) {
        this.$store.commit('file/setTransferPanelToggle', val);
      }
    }
  },
  created() {
    // 点击右击菜单外关闭
    document.addEventListener('click', () => {
      this.contextmuenPosition = '';
    });

    // 取消所有正在上传的任务
    this.$service.file.fileUploadCancelAll();

    $bus.$on('jumpToFolder', folder => {
      // 判断是否和最后一级相同
      if (
        (!folder.id && this.filePath.length < 1) ||
        (folder.id &&
          this.filePath.length &&
          folder.id === this.filePath[this.filePath.length - 1].id)
      )
        return;
      let text = '';
      if (folder.id) {
        text = '查看下级';
      } else {
        text = '查看全部';
      }
      this.checkIn(folder, text);
    });
  },
  mounted() {
    const vm = this;
    vm.tableMaxHeight = window.innerHeight - 298;
    window.onresize = function () {
      vm.tableMaxHeight = window.innerHeight - 298;
    };
  },
  activated() {
    this.getFileTableData();
  },
  methods: {
    // 上传文件
    handleFileChange(e) {
      this.dropdownState = false;
      if (!e.target.files[0]) return;
      this.surplusCapacity(e);
    },
    // 判断剩余容量是否足够
    surplusCapacity(e) {
      const file = e.target.files[0];
      const params = {
        fileSize: file.size
      };
      this.$service.file.fileCanUpload(params).then(res => {
        const { status, data } = res.data;
        if (status === 200) {
          e.target.value = '';
          if (data.canUpload === 0) {
            // 容量不够终止上传文件
            this.$message.error(data.uploadMsg);
          } else {
            // 获取切片信息
            this.getFileSectionInfo(file);
          }
        }
      });
    },
    // 获取文件切片信息
    getFileSectionInfo(file) {
      const { name, size } = file;
      const folder = this.filePath[this.filePath.length - 1] || {};
      const folderId = folder.id;
      // 显示传输面板
      this.transferPanelToggle = true;
      // 添加上传记录
      const taskId = this.$uuid();
      // MD5
      let bmf = new BMF();
      let task = {
        id: taskId,
        fileSectionIndex: 0,
        name,
        percentage: 0,
        bmf,
        md5Percentage: 0,
        transfreState: 'analysis',
        loadedSize: 0,
        loadedTotal: 0,
        folder
      };
      this.fileTasks[taskId] = task;
      // 生成上传列表数据
      this.fileTransList.push(task);
      // 文件MD5
      bmf.md5(
        file,
        (err, md5) => {
          if (err) return;
          const params = {
            md5,
            name,
            size,
            folderId
          };
          task.getUploadInfo = () =>
            this.getUploadInfo(params, task, taskId, file);
          // 获取文件切片信息
          this.getUploadInfo(params, task, taskId, file);
        },
        progress => {
          task.md5Percentage = (progress * 100).toFixedNum(1);
        }
      );
    },
    // 获取文件切片信息
    getUploadInfo(params, task, taskId, file) {
      this.$service.file
        .fileUploadPart(params)
        .then(res => {
          const { status, data } = res.data;
          if (status === 200) {
            task.taskId = data.id;
            // 上传文件
            // 正在上传文件大于3个时，设置为等待状态
            task.transfreState =
              this.fileTransList.filter(item => item.transfreState === 'pause')
                .length < 3
                ? 'pause'
                : 'await';

            this.uploadFile(data, file, taskId)
              .then(() => {})
              .catch(err => {
                // 判断错误类型
                // 除了主动取消、断网情况下，自动继续上传
                if (!['ERR_CANCELED', 'ERR_NETWORK'].includes(err.code)) {
                  // 重新传输
                  this.uploadFile(data, file, taskId);
                }
                // 断网情况下
                if (['ERR_NETWORK'].includes(err.code)) {
                  task.transfreState = 'retry';
                }
              });
          }
        })
        .catch(err => {
          // 判断错误类型
          // 除了主动取消、断网情况下，自动继续上传
          if (!['ERR_CANCELED', 'ERR_NETWORK'].includes(err.code)) {
            // 重新传输
            this.getUploadInfo(params, task, taskId, file);
          }
          // 断网情况下
          if (['ERR_NETWORK'].includes(err.code)) {
            task.transfreState = 'retry';
          }
        });
    },
    // 上传文件
    async uploadFile(data, file, taskId) {
      const { id, name, fileSize: total, partCount } = data;
      // 设置切片
      const preFileSize = 5 * 1024 * 1024;
      const fileReader = new FileReader();
      const bolbSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let task = this.fileTasks[taskId];
      task.id = id;
      task.loadedTotal = this.byteConvert(total);
      // 任务开始
      task.start = () => {
        // 判断当前正在上传文件个数
        task.transfreState =
          this.fileTransList.filter(item => item.transfreState === 'pause')
            .length < 3
            ? 'pause'
            : 'await';
        this.uploadFile(data, file, taskId)
          .then(() => {})
          .catch(err => {
            // 判断错误类型
            // 除了主动取消、断网情况下，自动继续上传
            if (!['ERR_CANCELED', 'ERR_NETWORK'].includes(err.code)) {
              // 重新传输
              this.uploadFile(data, file, taskId);
            }
            // 断网情况下
            if (['ERR_NETWORK'].includes(err.code)) {
              task.transfreState = 'retry';
            }
          });
      };

      while (
        task.fileSectionIndex < partCount &&
        task.transfreState === 'pause'
      ) {
        // 设置文件切片起始位置
        const start = task.fileSectionIndex * preFileSize;
        const end = start + preFileSize > total ? total : start + preFileSize;
        // 读取文件切片
        fileReader.readAsArrayBuffer(bolbSlice.call(file, start, end));
        // 文件切片
        const fielSection = await this.loadfileSection(fileReader, file);
        // 上传切片
        const res = await this.uploadFilePart(
          id,
          fielSection,
          task.fileSectionIndex + 1,
          start,
          total,
          task
        );
        if (res.data.status === 200) {
          // 部分上传完成
          if (res.data.data.uploadStatus === 1) {
            // 继续下一个切片上传
            task.fileSectionIndex++;
          }
          // 全部上传完成
          if (res.data.data.uploadStatus === 0) {
            this.$message.success(`${name}上传成功`);
            this.handleRefreshData();
            $bus.$emit('fileSpace');
            task.fileSectionIndex = partCount;
            task.transfreState = 'success';
            // 判断是否有等待中的任务
            for (let i = 0; i < this.fileTransList.length; i++) {
              const item = this.fileTransList[i];
              if (item.transfreState === 'await') {
                item.start();
                break;
              }
            }
          }
        } else if (res.data.status > 200 && res.data.status < 300) {
          // 当做任务完成
          this.$message.success(`${name}上传成功`);
          this.handleRefreshData();
          $bus.$emit('fileSpace');
          task.fileSectionIndex = partCount;
          task.transfreState = 'success';
          // 判断是否有等待中的任务
          for (let i = 0; i < this.fileTransList.length; i++) {
            const item = this.fileTransList[i];
            if (item.transfreState === 'await') {
              item.start();
              break;
            }
          }
        } else {
          // 重新传输
          this.uploadFile(data, file, taskId);
          break;
        }
      }
    },
    // 加载文件切片
    loadfileSection(fileReader, file) {
      const { name } = file;
      return new Promise((resolve, reject) => {
        fileReader.onload = function (e) {
          // 根据读取的内容生成文件
          let fielSection = new File([e.target.result], name, {
            type: file.type
          });
          resolve(fielSection);
        };
        fileReader.onerror = function () {
          this.error(`文件${name}读取出错，请检查该文件`);
          reject();
        };
      });
    },
    /**
     * 上传文件切片
     * @param {String} taskId [任务id]
     * @param {File} file [文件切片]
     * @param {Number} fileIndex [切片顺序]
     * @param {Number} fileLoadedSize [文件已上传大小]
     * @param {Number} fileTotalSize [文件总大小]
     * @param {Object} task [上传列表任务数据]
     */
    uploadFilePart(
      taskId,
      file,
      fileIndex,
      fileLoadedSize,
      fileTotalSize,
      task
    ) {
      let data = new FormData();
      data.append('taskId', taskId);
      data.append('file', file);
      data.append('index', fileIndex);
      // 用于控制取消请求
      const controller = new AbortController();
      task.controller = controller;
      return this.$service.file.fileUploadPartPost(data, {
        signal: controller.signal,
        onUploadProgress: progressEvent => {
          // 设置上传进度条
          const loadedSize = progressEvent.loaded + fileLoadedSize;
          const percentage = (loadedSize / fileTotalSize) * 100;
          // 避免取消上传，在此继续上传时，进度条回退
          if (percentage >= task.percentage) {
            task.percentage = percentage > 100 ? 100 : percentage;
            task.loadedSize = this.byteConvert(loadedSize);
          }
        }
      });
    },
    // 复制移动页面滚动加载
    loadMoreData() {
      if (this.fileMoveOrCopyPage < this.fileMoveOrCopyTotalPage) {
        this.fileMoveOrCopyPage = this.fileMoveOrCopyPage + 1;
      } else {
        return;
      }
      this.getFileListData(this.getTargetFileId());
    },
    // 分页切换刷新
    handleCurrentChange(val) {
      this.page = val;
      if (this.showTableCustomColumn) {
        this.keyword = this.filePath[0].keyword;
        this.handleSearch();
      } else {
        this.keyword = '';
        this.handleRefreshData();
      }
    },
    // 获取文件夹数据
    getFileTableData(id) {
      this.loading = true;
      const params = {
        page: this.page,
        parentId: id,
        sortType: this.sortChangeNum
      };
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      this.$service.file
        .fileMy(params)
        .then(data => {
          if (data.data.status === 200) {
            if (
              data.data.totalPage < data.data.page &&
              data.data.totalPage != 0
            ) {
              this.page = 1;
              this.getFileTableData();
            }
            this.tableData = data.data.data;
            this.page = data.data.page;
            this.total = data.data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // 关闭右击菜单
      this.contextmuenPosition = '';
      this.$store.commit('common/setEditTextId', '');
    },
    // 移动文件夹窗口数据
    getFileListData(id) {
      this.loadSign = false;
      let selfId = this.checkedFileId.join(',');
      this.fileMoveCopyLoading = true;
      const params = {
        selfId: selfId,
        parentId: id,
        page: this.fileMoveOrCopyPage
      };
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      this.$service.file.fileMoveOrCopy(params).then(data => {
        if (data.data.status === 200) {
          this.fileList = this.fileList.concat(data.data.data);
          this.fileMoveOrCopyPage = data.data.page;
          this.fileMoveOrCopyTotalPage = data.data.totalPage;
        }
      });
    },
    // 排序查询
    handleSortChange(data) {
      if (data.prop == 'name') {
        switch (data.order) {
          case 'ascending':
            this.sortChangeNum = 1;
            break;
          case 'descending':
            this.sortChangeNum = 2;
            break;
          default:
            this.sortChangeNum = 0;
            break;
        }
      } else if (data.prop == 'updateDateText') {
        switch (data.order) {
          case 'ascending':
            this.sortChangeNum = 3;
            break;
          case 'descending':
            this.sortChangeNum = 4;
            break;
          default:
            this.sortChangeNum = 0;
            break;
        }
      } else if (data.prop == 'sizeFormat') {
        switch (data.order) {
          case 'ascending':
            this.sortChangeNum = 5;
            break;
          case 'descending':
            this.sortChangeNum = 6;
            break;
          default:
            this.sortChangeNum = 0;
            break;
        }
      }
      this.handleCurrentChange(this.page);
    },
    // 创建文件夹-文件(夹)重命名
    handleNameEdit(text) {
      if (!text) {
        this.$message.error('文件(夹)名称不能为空，请输入文件名称');
      } else {
        this.loading = true;
        if (this.checkedFileId[0] > 100000000) {
          const params = {
            name: text,
            fileId: this.checkedFileId[0]
          };
          // 重命名
          this.$service.file
            .fileModifyName(params)
            .then(data => {
              if (data.data.status === 200) {
                this.$store.commit('common/setEditTextId', '');
                this.$message.success(data.data.msg);
                this.handleRefreshData();
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          const params = {
            name: text,
            parentFolderId: this.getParentFolderId()
          };
          // 创建文件夹
          this.$service.file
            .fileMkdir(params)
            .then(data => {
              if (data.data.status === 200) {
                this.$store.commit('common/setEditTextId', '');
                this.$message.success(data.data.msg);
                this.handleRefreshData();
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    // 刷新数据
    handleRefreshData() {
      this.getFileTableData(this.getParentFolderId());
    },
    handleUploadBtnClick() {
      if (this.loading) return;
      this.dropdownState = !this.dropdownState;
    },
    // 跳转指定目录
    jump(data) {
      if (data.parentFolder) {
        // 修改
        this.filePath = [{ name: data.parentFolder }];
      } else {
        // 查看全部
        this.filePath = [];
      }
    },
    // 移动窗口
    moveOrCopy(val) {
      this.moveOrCopyTitle = val;
      this.fileMoveOrCopyPage = 1;
      this.getFileListData();
      this.fileList = [];
      this.fileMovePath = [];
      this.eidtFileNameId = this.contextmuenRow.id;
      this.fileMoveDialogVisible = true;
    },
    // 提交移动请求
    handleMove() {
      let targetFileId = this.getTargetFileId() ? this.getTargetFileId() : 0;
      this.loading = true;
      let params = new FormData();
      for (let i = 0; i < this.checkedFileId.length; i++) {
        const element = this.checkedFileId[i];
        params.append('fileId', element);
        params.append('targetFileId', targetFileId);
      }
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      this.$service.file
        .fileMoveBatch(params)
        .then(data => {
          if (data.data.status === 200) {
            this.$message.success(data.data.msg);
            this.fileMoveDialogVisible = false;
            this.handleCurrentChange(1);
          }
          this.eidtFileNameId = '';
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 删除
    del() {
      this.$confirm(
        '文件删除后数据无法恢复，确定删除所选的文件吗？',
        '确定删除',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.loading = true;
          let params = new FormData();
          for (let i = 0; i < this.checkedFileId.length; i++) {
            const element = this.checkedFileId[i];
            params.append('fileId', element);
          }
          this.$service.file
            .fileDeleteBatch(params)
            .then(data => {
              if (data.data.status === 200) {
                this.$message.success(data.data.msg);
                this.handleCurrentChange(this.page);
                $bus.$emit('fileSpace', '');
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 重命名
    reaname() {
      this.$store.commit('common/setEditTextId', this.checkedFileId[0]);
    },
    // 单元格右键点击
    handleRowContextmenu(row, column, e) {
      // 文件夹如有文件在上传禁止右键
      if (row.folder == 1 && this.fileTransList) {
        for (let item of this.fileTransList) {
          if (row.id == item.folder?.id && item.transfreState !== 'success') {
            this.$message.warning('当前文件夹正在上传文件,禁止编辑');
            return;
          }
        }
      }
      this.contextmuenRow = row;
      this.contextmuenPosition = {
        left: e.pageX + 'px',
        top: e.pageY + 'px'
      };
      if (this.checkedFileId.includes(row.id)) return;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 点击行事件
    handleRowClick(row) {
      if (row.folder == 1 && this.fileTransList) {
        for (let item of this.fileTransList) {
          if (row.id == item.folder?.id && item.transfreState !== 'success')
            return this.$message.warning('当前文件夹正在上传文件,禁止编辑');
        }
      }
      if (this.editTextId) return;
      if (this.$refs.multipleTable.selection.length == 1) {
        var rowClickId = this.$refs.multipleTable.selection[0].id;
      }
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      let rowClickId1 = this.$refs.multipleTable.selection[0].id;
      if (rowClickId == rowClickId1) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 查看上一级
    checkOut(val) {
      if (this.loading) return;
      // 移动复制页查看上一级
      this.page = 1;
      if (val == 'fileMove') {
        let parentFolderId =
          this.fileMovePath.length >= 2
            ? this.fileMovePath[this.fileMovePath.length - 2].id
            : '';
        this.fileList = [];
        this.fileMoveOrCopyPage = 1;
        this.getFileListData(parentFolderId);
        this.checkedFile = [];
        this.fileMovePath.pop();
      } else {
        // 搜索时查看上一级
        if (!this.filePath[0].id && this.filePath.length === 2) {
          this.keyword = this.filePath[0].keyword;
          this.filePath = [];
          this.filePath.push({
            name: '"' + this.keyword + '"的搜索结果',
            keyword: this.keyword
          });
          this.handleSearch(this.keyword);
        } else {
          // 查看上一级
          this.keyword = '';
          this.showTableCustomColumn = false;
          let parentFolderId =
            this.filePath.length >= 2
              ? this.filePath[this.filePath.length - 2].id
              : '';
          this.getFileTableData(parentFolderId);
          this.filePath.pop();
        }
        this.checkedFile = [];
      }
    },
    // 查看下一级
    checkIn(item, val) {
      if (this.loading) return;
      this.keyword = '';
      this.page = 1;
      this.checkedFile = [];
      if (val === '查看下级') {
        if (item.folder == 0) return;
        this.showTableCustomColumn = false;
        this.getFileTableData(item.id);
        this.filePath.push(item);
      } else {
        // 搜索查看目录
        this.getFileTableData(item.parentFolderId);
        this.showTableCustomColumn = false;
        if (!item.parentName) {
          this.filePath = [];
        } else {
          item.name = item.parentName;
          this.filePath.push(item);
        }
      }
    },
    // 查看下一级
    checkMoveIn(item) {
      if (item.folder == 0) return;
      this.fileList = [];
      this.fileMoveOrCopyPage = 1;
      this.getFileListData(item.id);
      this.fileMovePath.push(item);
    },
    // 查看根目录
    checkRoot(val) {
      if (this.loading) return;
      if (val == 'fileMove') {
        this.fileMovePath = [];
        this.fileList = [];
        this.fileMoveOrCopyPage = 1;
        this.getFileListData();
      } else {
        this.checkedFile = [];
        this.filePath = [];
        this.keyword = '';
        this.showTableCustomColumn = false;
        this.page = 1;
        this.getFileTableData();
      }
    },
    // 批量操作文件
    handleFile(type) {
      if (type === 'move') {
        // 批量移动
        this.moveOrCopy('移动到');
      } else if (type === 'del') {
        // 批量删除
        this.del();
      } else if (type === 'copy') {
        // 批量复制
        this.moveOrCopy('复制到');
      } else if (type === 'cancel') {
        // 取消选择
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 有文件正在上传的文件夹禁止选中
    checkboxSelect(row) {
      if (row.folder == 1 && this.fileTransList) {
        for (let item of this.fileTransList) {
          if (row.id == item.folder?.id && item.transfreState !== 'success') {
            return false;
          }
        }
      }
      return true;
    },
    // 表格勾选变化数据
    handleSelectionChange(data) {
      this.checkedFileId = [];
      this.checkedFile = data;
      data.forEach(item => {
        this.checkedFileId.push(item.id);
      });
    },
    // 选中行颜色
    tableRowClassName(row) {
      if (this.checkedFileId.includes(row.row.id)) {
        return 'warm-row';
      }
    },
    // 上传文件夹
    handleFolderChange(e) {
      let parentFolderId = this.getParentFolderId();
      this.dropdownState = false;
      // 取文件夹名称
      let folderName = this.$refs.fileRef.files[0].webkitRelativePath.replace(
        '/' + this.$refs.fileRef.files[0].name,
        ''
      );
      // 重新遍历赋值文件名称
      let fileList = this.$refs.fileRef.files;
      let canUpload = 0;
      let formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        let element = new File([fileList[i]], fileList[i].name, {
          type: fileList[i].type
        });
        formData.append('files', element);
        canUpload = canUpload + fileList[i].size;
      }
      formData.append('folderName', folderName);
      formData.append('parentId', parentFolderId);
      //  查看储存容量是否足够
      const params = {
        fileSize: canUpload
      };
      this.$service.file.fileCanUpload(params).then(res => {
        if (res.data.status === 200 && res.data.data.canUpload === 0) {
          // 容量不够终止上传文件
          this.$message.error(res.data.data.uploadMsg);
          e.target.value = '';
        } else {
          this.$message('请勿刷新页面避免数据丢失，正在加速上传中~');
          // 取消请求
          const controller = new AbortController();
          // 上传：地址-时间-取消-进度
          let config = {
            baseURL: window.fileIp,
            timeout: 24 * 60 * 60 * 1000,
            signal: controller.signal,
            onUploadProgress: progressEvent => {
              let persent =
                ((progressEvent.loaded / progressEvent.total) * 100) | 0; //上传进度百分比
              let fileTransList = [
                {
                  id: 2,
                  name: folderName,
                  type: 'folder',
                  loadedSize: this.byteConvert(progressEvent.loaded),
                  loadedTotal: this.byteConvert(progressEvent.total),
                  percentage: persent
                }
              ];
              this.$store.commit('file/setFileTransList', fileTransList);
            }
          };
          // 打开文件传输面板
          $bus.$emit('transfer', true);
          axios.post('/user/file/uploadFolder', formData, config).then(data => {
            if (data.data.status === 200) {
              if (this.showTableCustomColumn) {
                this.$message.success(
                  folderName + '文件夹已上传到全部文件目录'
                );
              } else {
                this.$message.success(folderName + '文件夹' + data.data.msg);
              }

              this.handleCurrentChange(this.page);
              $bus.$emit('fileSpace', '');
            }
          });
          e.target.value = '';
        }
      });
    },
    // 搜索
    handleSearch(val) {
      if (this.loading) return;
      if (!this.keyword) {
        this.$message.error('请输入搜索关键字');
        return;
      }
      this.loading = true;
      this.showTableCustomColumn = true;
      const keyword = this.keyword;
      const params = {
        page: this.page,
        keywords: val ? val : this.keyword,
        sortType: this.sortChangeNum
      };
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      this.filePath = [];
      this.filePath.push({
        name: '“' + keyword + '"的搜索结果',
        keyword: keyword
      });
      this.$service.file
        .fileSearch(params)
        .then(data => {
          if (data.data.status === 200) {
            if (
              data.data.totalPage < data.data.page &&
              data.data.totalPage != 0
            ) {
              this.page = 1;
              this.handleSearch();
            }
            this.tableData = data.data.data;
            this.page = data.data.page;
            this.total = data.data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 取消搜索
    handleSearchClose() {
      (this.keyword = ''),
        (this.showTableCustomColumn = false),
        (this.filePath = []);
      this.handleRefreshData();
    },
    // 新建文件夹
    createFolder(editTextId) {
      this.eidtFileNameId = '';
      if (editTextId !== '' || this.loading) return;
      this.getCurrentTime();
      var newChild = [
        {
          id: Math.floor(Math.random() * 10000 + 100000) + '',
          folder: 1,
          fileSize: 0,
          updateDateText: this.currentTime,
          name: '新建文件夹',
          sizeFormat: '0.0KB'
        }
      ];
      this.tableData = newChild.concat(this.tableData);
      this.$store.commit('common/setEditTextId', newChild[0].id);
      this.$refs.multipleTable.toggleRowSelection(this.tableData[0]);
    },
    // 获取当前时间
    getCurrentTime() {
      var timestamp = Date.parse(new Date());
      this.currentTime = time.formatTime(Number(timestamp), 'yyyy-MM-dd&hh:mm');
    },
    // 转化字节大小
    byteConvert(bytes) {
      if (isNaN(bytes)) {
        return '';
      }
      let symbols = ['b', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let exp = Math.floor(Math.log(bytes) / Math.log(2));
      if (exp < 1) {
        exp = 0;
      }
      let i = Math.floor(exp / 10);
      bytes = bytes / Math.pow(2, 10 * i);

      if (bytes.toString().length > bytes.toFixed(2).toString().length) {
        bytes = bytes.toFixed(2);
      }
      return bytes + symbols[i];
    },
    // 获取当前目录Id
    getParentFolderId() {
      if (this.filePath.length === 0) return '';
      let parentFolderId = '';
      // 当前目录的id
      if (this.filePath[0].id) {
        parentFolderId = this.filePath[this.filePath.length - 1].id;
      } else {
        // 路径为取搜索时的页面目录ip
        if (this.showTableCustomColumn) {
          parentFolderId =
            this.filePath[this.filePath.length - 1].parentFolderId;
        } else {
          parentFolderId = this.filePath[this.filePath.length - 1].id;
        }
      }
      return parentFolderId;
    },
    // 获取复制移动页面当前目录Id
    getTargetFileId() {
      if (this.fileMovePath.length === 0) return '';
      let targetFileId = this.fileMovePath[this.fileMovePath.length - 1].id;
      return targetFileId;
    }
  }
};
</script>

<style lang="less" scoped>
.main-header {
  justify-content: space-between;
  // 文件地址栏
  .filePath {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 20px;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    // 返回按钮
    &-back {
      display: flex;
      align-items: center;
      &-btn {
        color: #507cff;
        font-size: 20px;
      }
      // 分割线
      .splitLine {
        width: 1px;
        height: 20px;
        margin: 0 10px;
        background: #507cff;
      }
    }
    // 查看全部
    &-checkAll {
      color: #333;
      font-size: 20px;
      cursor: pointer;
      &.active {
        color: #507cff;
      }
    }
    // 文件地址列表
    &-list {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 18px;
      &-item {
        display: flex;
        align-items: center;
        .icon-arrow-right {
          color: #666666;
          font-size: 24px;
        }
      }
    }
  }
  .loading-num {
    color: #666666;
    font-size: 18px;
  }
}
// 上传按钮
.fileInput {
  display: block;
  height: 100%;
  cursor: pointer;
  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
// 头部左侧按钮
.header-left {
  &.fileBtn-group {
    .header-btn {
      border: 1px solid #507cff;
      background-color: #fff;
      color: #507cff;
      cursor: pointer;
      &:active {
        filter: brightness(120%);
      }
    }
  }
}

// 上传文件下拉框
.dropdown {
  position: relative;
  margin-right: 27px;
  &-toggle {
    margin-right: 0 !important;
  }
  &-list {
    position: absolute;
    z-index: 2;
    top: 48px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 6px 8px 0px rgba(71, 105, 163, 0.15);
    overflow: hidden;
    &-item {
      height: 40px;
      line-height: 40px;
      color: #333333;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #fefefe;
        background-color: #507cff;
      }
    }
  }
}

// 输入框样式
/deep/.editText {
  margin-left: 40px;
  &-text {
    span:hover {
      color: #507cff;
      cursor: pointer;
    }
  }
}
.tableColumn {
  display: flex;
  align-items: center;
}
.table-fileName,
.table-folderName {
  margin-left: 38px;
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
  overflow: hidden;
  white-space: nowrap;
}
.table-folderName {
  cursor: pointer;
  &:hover {
    color: #507cff;
  }
}
.showTableCustomColumn {
  color: #507cff;
  font-size: 18px;
  cursor: pointer;
}
.folderImg {
  width: 28px;
  height: 28px;
  margin-right: 12px;
  top: 13px;
  position: absolute;
}
.folderImg1 {
  width: 16px;
  height: 16px;
  left: 16px;
  top: 22px;
  position: absolute;
  z-index: 1;
}
/deep/.el-table .cell {
  line-height: 38px;
}

// 没有数据
.noData {
  width: 274px;
  padding-bottom: 50px;
  margin: 50px auto 0 auto;
  text-align: center;
  &-img {
    width: 274px;
    height: 110px;
    margin-bottom: 60px;
  }
  // 底部
  &-bottom {
    &-text {
      margin-bottom: 30px;
      color: #666666;
      font-size: 20px;
    }
    &-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 117px;
      height: 127px;
      background: #f7f9fc;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        border-color: rgba(80, 124, 255, 0.3);
        background: rgba(80, 124, 255, 0.1);
        box-shadow: 0px 5px 10px 1px rgba(236, 239, 255, 0.5);
      }
      img {
        width: 54px;
        height: 54px;
      }
      &-group {
        display: flex;
        justify-content: center;
        // justify-content: space-between;
      }
    }
  }
}

// 右键菜单
.contextMenu {
  position: absolute;
  // 菜单列表
  &-list {
    width: 106px;
    background-color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(71, 105, 163, 0.15);
    border-radius: 6px;
    overflow: hidden;
    // 列表项
    &-item {
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #333;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: #507cff;
      }
    }
  }
}

// 移动文件窗口
.fileMoveDialog {
  /deep/.el-dialog__body {
    padding: 0;
  }
  &-header {
    padding: 0 30px;
    width: 100%;
    height: 40px !important;
    line-height: 40px;
    background: #efefefa8;
    &-filePath {
      align-items: center;
      font-size: 14px;
      user-select: none;
      width: 500px;
      .handleTxet {
        cursor: pointer;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
        color: #507cff;
        margin-right: 5px;
        .folderImgTitle {
          position: absolute;
          margin-top: 8px;
          width: 22px;
        }
      }
      .qbwj {
        margin-left: 30px;
        color: #333;
        font-size: 15px;
      }
      .list {
        color: #999;
        display: inline-block;
      }
    }
  }
  .fileMoveTable {
    /deep/.el-table__cell {
      padding: 0;
      line-height: 0;
      height: 48px !important;
      border-color: rgba(233, 233, 233, 0.631);
    }
    .folderImg {
      margin-left: 35px;
      width: 20px;
      height: 20px;
    }
    .folderName {
      margin-left: 65px;
      font-size: 14px;
    }
  }
  // 分页
  &-pagination {
    bottom: 100px;
    width: 770px;
  }
  // 按钮
  &-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    margin: 30px 0;
    .close {
      color: #507cff;
    }
    /deep/.el-button {
      margin-right: 40px;
      font-size: 18px;
      width: 120px;
      height: 40px;
      border: none;
      border-radius: 12px;
      border: 1px solid #507cff;
    }
  }
}
// 上传压缩包窗口
/deep/.uploadRARDialog {
  .el-dialog__body {
    padding: 20px 40px 30px 50px;
  }
  .el-form {
    &-item__label {
      font-size: 18px;
      padding: 0;
      margin: 0;
    }
  }
  .upload-demo {
    .el-upload-dragger {
      width: 316px;
      height: 166px;
    }
    .uploadRar {
      margin-top: 44px;
      width: 46px;
      height: 46px;
    }
    .el-upload__text {
      margin-top: -14px;
      font-size: 16px;
      color: #507cff;
      img {
        margin-right: 5px;
      }
    }
  }

  &-announcements {
    margin-top: -20px;
    .el-form-item__label {
      font-size: 16px;
    }
    img {
      margin: 0 5px 0 -15px;
    }
    &-p1 {
      margin-top: 10px;
    }
    p {
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
// 表格行背景色
/deep/.warm-row {
  background-color: #f5f8ff !important;
}
</style>
